<template>
    <div class="d-flex white-space-pre">
        <p :class="partialLensDescriptionClasses(lensDescriptionDetails, 'modelName')">
            <span>{{
                processPartialLensDescription(lensDescriptionDetails, 'modelName')
                    .textWithoutEnclosures
            }}</span>
        </p>
        <p v-if="lensDescriptionDetails['modelSeparator']" :class="normalTextClasses">
            <span>{{ lensDescriptionDetails['modelSeparator'] }}</span>
        </p>
        <p :class="partialLensDescriptionClasses(lensDescriptionDetails, 'modelLength')">
            <span>{{
                processPartialLensDescription(lensDescriptionDetails, 'modelLength')
                    .textWithoutEnclosures
            }}</span>
        </p>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import get from 'lodash/get';
import {replaceEnclosures, decimalSeparatorFormatter} from '@/utilities/formatters';

/**
 * Displays the lens model with styling. Partial lens descriptions that are
 * enclosed with braces e.g. {{13.1}} will be displayed with the highlight
 * styles. Otherwise they are displayed with the normal styles.
 */
export default {
    name: 'LensModel',
    props: {
        /**
         * Details about the lens description which should include: modelName,
         * modelLength, and modelSeparator
         */
        lensDescriptionDetails: {
            type: Object,
            required: true,
        },
        /**
         * The classes to apply to normal text.
         */
        normalTextClasses: {
            type: String,
            required: false,
            default: 'text-nowrap text-caption heavy text-black mb-1 pl-0 ml-0',
        },
        /**
         * The classes to apply to highlighted text.
         */
        highlightTextClasses: {
            type: String,
            required: false,
            default: 'text-nowrap text-caption heavy text-red mb-1 pl-0 ml-0',
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
    },
    methods: {
        /**
         * Process the partial lens description.
         *
         * @param {Object} lensDescriptionDetail - the full lens description details
         * @param {String} lensPortion - the portion of the lens description to process
         *
         * @returns {Object} an object containing the text without any double braces
         *   and whether any double braces were removed or not
         */
        processPartialLensDescription(lensDescriptionDetail, lensPortion) {
            let lensPortionValue = get(lensDescriptionDetail, lensPortion, '');
            // Remove any braces
            return replaceEnclosures(lensPortionValue);
        },

        /**
         * Get the classes for the partial lens description. If the partial lens
         * description has enclosures, which in this care are braces, then return
         * the classes for highlighting. Otherwise, return the classes for normal
         * text.
         *
         * @param {Object} lensDescriptionDetail - the full lens description details
         * @param {String} lensPortion - the portion of the lens description to process
         *
         * @returns {Array} the classes for the partial lens description
         */
        partialLensDescriptionClasses(lensDescriptionDetail, lensPortion) {
            let classes = [];

            if (
                this.processPartialLensDescription(lensDescriptionDetail, lensPortion)
                    .enclosuresReplaced
            ) {
                classes = this.highlightTextClasses.split(' ');
            } else {
                classes = this.normalTextClasses.split(' ');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss" scoped></style>
