<template>
    <div class="d-flex white-space-pre">
        <p :class="partialLensDescriptionClasses(lensDescriptionDetails, 'sphere')">
            <span>{{
                processPartialLensDescription(lensDescriptionDetails, 'sphere')
                    .textWithoutEnclosures
            }}</span>
        </p>
        <p :class="partialLensDescriptionClasses(lensDescriptionDetails, 'cylinder')">
            <span>{{
                processPartialLensDescription(lensDescriptionDetails, 'cylinder')
                    .textWithoutEnclosures
            }}</span>
        </p>
        <p
            v-if="
                processPartialLensDescription(lensDescriptionDetails, 'cylinder')
                    .textWithoutEnclosures
            "
            :class="normalTextClasses"
        >
            <span>x</span>
        </p>
        <p :class="partialLensDescriptionClasses(lensDescriptionDetails, 'axis')">
            <span>{{
                processPartialLensDescription(lensDescriptionDetails, 'axis').textWithoutEnclosures
            }}</span>
        </p>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import get from 'lodash/get';
import {replaceEnclosures, decimalSeparatorFormatter} from '@/utilities/formatters';

/**
 * Displays the lens prescription with styling. Partial lens descriptions that
 * are enclosed with braces e.g. {{-5.00}} will be displayed with the highlight
 * styles. Otherwise they are displayed with the normal styles.
 */
export default {
    name: 'LensPrescription',
    props: {
        /**
         * Details about the lens description which could include: sphere,
         * cylinder, and axis.
         */
        lensDescriptionDetails: {
            type: Object,
            required: true,
        },
        /**
         * The classes to apply to normal text.
         */
        normalTextClasses: {
            type: String,
            required: false,
            default: 'text-caption text-gray-dark light mb-0',
        },
        /**
         * The classes to apply to highlighted text.
         */
        highlightTextClasses: {
            type: String,
            required: false,
            default: 'text-caption text-red light mb-0',
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
    },
    methods: {
        /**
         * Process the partial lens description.
         *
         * @param {Object} lensDescriptionDetail - the full lens description details
         * @param {String} lensPortion - the portion of the lens description to process
         *
         * @returns {Object} an object containing the text without any double braces
         *   and whether any double braces were removed or not
         */
        processPartialLensDescription(lensDescriptionDetail, lensPortion) {
            let output;

            // For each lens portion, replace any decimals with the current user's
            // decimal separator and remove any double braces.
            if (lensPortion === 'sphere' || lensPortion === 'axis') {
                let lensPortionValue = get(lensDescriptionDetail, lensPortion, '');
                lensPortionValue = decimalSeparatorFormatter(
                    lensPortionValue,
                    this.currentUser.decimalSeparator
                );
                output = replaceEnclosures(lensPortionValue);
            } else if (lensPortion === 'cylinder') {
                let lensPortionValue = get(lensDescriptionDetail, lensPortion);
                lensPortionValue = decimalSeparatorFormatter(
                    lensPortionValue,
                    this.currentUser.decimalSeparator
                );
                output = replaceEnclosures(lensPortionValue ? ' ' + lensPortionValue + ' ' : '');
            }

            return output;
        },

        /**
         * Get the classes for the partial lens description. If the partial lens
         * description has enclosures, which in this care are braces, then return
         * the classes for highlighting. Otherwise, return the classes for normal
         * text.
         *
         * @param {Object} lensDescriptionDetail - the full lens description details
         * @param {String} lensPortion - the portion of the lens description to process
         *
         * @returns {Array} the classes for the partial lens description
         */
        partialLensDescriptionClasses(lensDescriptionDetail, lensPortion) {
            let classes = [];

            if (
                this.processPartialLensDescription(lensDescriptionDetail, lensPortion)
                    .enclosuresReplaced
            ) {
                classes = this.highlightTextClasses.split(' ');
            } else {
                classes = this.normalTextClasses.split(' ');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss" scoped></style>
