<!--This is the Reservation page, with the list of all reservations. -->
<template>
    <b-container fluid class="list-container py-4">
        <b-row class="mb-4">
            <b-col
                class="d-flex flex-column align-items-start justify-content-between flex-grow flex-sm-row align-items-sm-center"
            >
                <h1 class="text-primary mb-0">
                    {{ t(isPreOrderListMode ? 'preorders' : 'reservationTitle') }}
                </h1>
            </b-col>
        </b-row>

        <b-card>
            <b-row class="mb-2">
                <b-col cols="12" lg="5" xl="4">
                    <h4 class="heavy mb-0">
                        {{ t(isPreOrderListMode ? 'preordersList' : 'reservationList') }}
                    </h4>
                    <p class="gray-dark mb-0">
                        {{
                            t(isPreOrderListMode ? 'preordersList_Desc1' : 'reservationList_Desc1')
                        }}
                    </p>
                    <p v-if="isDefaultSort" class="gray-dark">
                        {{
                            t(isPreOrderListMode ? 'preordersList_Desc2' : 'reservationList_Desc2')
                        }}
                    </p>
                </b-col>

                <b-col
                    cols="12"
                    lg="7"
                    xl="8"
                    class="d-flex align-items-start justify-content-between flex-column flex-sm-row mb-2 mb-lg-0"
                >
                    <b-form @submit.prevent="refreshRecords(1)" class="d-flex mb-2 mb-sm-0">
                        <b-input-group class="px-0 mr-2 mb-lg-0">
                            <b-input-group-prepend is-text>
                                <b-icon icon="search"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input
                                type="search"
                                inputmode="numeric"
                                v-model="queryParams.search"
                                :formatter="formatSearchInput"
                                :placeholder="
                                    t(
                                        isPreOrderListMode
                                            ? 'preordernumber'
                                            : 'reservationDetails_OrderNumber'
                                    )
                                "
                                id="searchInput"
                            ></b-form-input>
                        </b-input-group>

                        <div>
                            <b-button type="submit" variant="primary"> {{ t('search') }} </b-button>
                        </div>
                    </b-form>

                    <div class="d-flex flex-column align-items-start align-items-lg-end mb-0">
                        <div class="d-flex align-items-start">
                            <ReserveFilters
                                v-if="!isDistributorSurgeonMode"
                                @change="refreshRecords(1, $event)"
                            />
                            <b-button
                                @click="exportData('reservations', exportFileName, exportParams)"
                                variant="secondary"
                            >
                                {{ t('export') }} <b-icon-box-arrow-right class="ml-1" />
                            </b-button>
                        </div>
                        <div
                            :class="[
                                get($refs, 'reserveTable.selectedRows.length', 0)
                                    ? 'd-flex mt-1'
                                    : 'd-none',
                            ]"
                        >
                            <b-button
                                variant="white"
                                size="sm"
                                class="px-2"
                                v-b-tooltip.hover
                                :title="t('addToCart')"
                                @click="bulkOperationPrompt(bulkActions.ADD_TO_CART)"
                            >
                                <b-icon
                                    icon="cart-plus-fill"
                                    scale="1.2"
                                    variant="primary"
                                ></b-icon>
                            </b-button>

                            <b-button
                                variant="white"
                                size="sm"
                                class="px-2"
                                v-b-tooltip.hover
                                :title="t('cancelReservations')"
                                @click="bulkOperationPrompt(bulkActions.CANCEL_RESERVATION)"
                            >
                                <b-icon icon="trash-fill" scale="1.2" variant="danger"></b-icon>
                            </b-button>
                            <span class="align-self-center gray-dark">
                                {{
                                    t('rowsSelected', {
                                        length: get($refs, 'reserveTable.selectedRows.length', 0),
                                    })
                                }}
                            </span>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <ReserveTable
                ref="reserveTable"
                :query-params="queryParams"
                :is-pre-order-list-mode="isPreOrderListMode"
                @sort-changed="sortChanged"
                @record-changed="refreshRecords(selectPageAfterAction)"
            />

            <b-row>
                <b-col cols="2" offset="10">
                    <b-pagination
                        v-if="pageDetails.total"
                        @change="refreshRecords"
                        align="right"
                        size="sm"
                        :value="queryParams.page"
                        :total-rows="pageDetails.total"
                        :per-page="queryParams.perPage"
                    ></b-pagination>
                </b-col>
            </b-row>
        </b-card>
        <p class="font-weight-lighter mt-2">
            <small>{{ t('disclaimer') }} </small>
        </p>

        <b-overlay :show="showRemovePrompt" no-wrap fixed>
            <template #overlay>
                <div
                    ref="dialog"
                    tabindex="-1"
                    role="dialog"
                    aria-modal="false"
                    aria-labelledby="form-confirm-label"
                    class="text-center p-3"
                >
                    <h3>
                        <strong id="form-confirm-label">
                            {{
                                t(`${bulkOperationStatus}SelectedReservations`, {
                                    length: get($refs, 'reserveTable.selectedRows.length', 0),
                                })
                            }}
                        </strong>
                    </h3>
                    <div class="d-flex center mt-3">
                        <b-button
                            variant="outline-danger"
                            size="lg"
                            class="mr-3"
                            @click="showRemovePrompt = false"
                        >
                            {{ t('no') }}
                        </b-button>
                        <b-button
                            variant="outline-success"
                            size="lg"
                            @click="onBulkOperationConfirmed"
                            >{{ t('yes') }}</b-button
                        >
                    </div>
                </div>
            </template>
        </b-overlay>
    </b-container>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import snakeCase from 'lodash/snakeCase';
import merge from 'lodash/merge';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import get from 'lodash/get';
import ReserveFilters from './components/ReserveFilters.vue';
import ReserveTable from './components/ReserveTable.vue';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

const status = 'res';
const defaultSort = {
    sortOrder: 'desc',
    sortBy: 'order_date',
};
const defaultQueryParams = (isPreOrderListMode, isDistributorSurgeonMode, isDistributorMode) => ({
    search: null,
    page: 1,
    perPage: 10,
    ...defaultSort,
    ...(isPreOrderListMode && {
        hideInCart: 1,
        hideReservation: 1,
        hidePreOrderReservation: 1,
        showCancelled: 1,
    }),
    ...(!isPreOrderListMode && {
        hidePreOrderReservation: isDistributorSurgeonMode ? null : 1,
        hidePreOrder: isDistributorSurgeonMode ? 1 : null,
        hideOrdered: isDistributorSurgeonMode || isDistributorMode ? 1 : null,
        hideReservation: isDistributorSurgeonMode ? 1 : null,
    }),
});

export default {
    name: 'ReserveList',
    components: {ReserveFilters, ReserveTable},
    props: {
        isPreOrderListMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            queryParams: {page: 1, perPage: 10},
            showRemovePrompt: false,
            bulkOperationStatus: null,
        };
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
        ...mapState({
            pageDetails: (state) => state.reservations.details,
            orders: (state) => state.reservations.list,
        }),
        isDistributorMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.DISTRIBUTOR],
            });
        },
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.SURGEON],
            });
        },
        exportFileName() {
            return this.$route.name == 'PreOrderList'
                ? this.$t('preorders')
                : this.$t('reservationTitle');
        },
        bulkActions() {
            return {
                ADD_TO_CART: 'addtocart',
                CANCEL_RESERVATION: 'cancel',
            };
        },
        selectPageAfterAction() {
            if (this.orders.length === 1 && this.queryParams.page > 1) {
                return this.queryParams.page - this.orders.length;
            }
            return this.queryParams.page;
        },
        isDefaultSort() {
            return (
                defaultSort.sortOrder === this.queryParams.sortOrder &&
                defaultSort.sortBy === this.queryParams.sortBy
            );
        },
        queryObject() {
            // Removing empty property
            return Object.keys(this.queryParams)
                .filter((key) => !isNil(this.queryParams[key]))
                .reduce((acc, key) => ({...acc, [key]: this.queryParams[key]}), {});
        },
        exportParams() {
            return omit(this.queryObject, ['page', 'perPage']);
        },
    },
    methods: {
        get,
        ...mapActions('reservations', ['clearList']),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        formatSearchInput(value) {
            // Use a regular expression to match only digits
            const numericString = value.replace(/\D/g, '');

            if (!numericString) return numericString;

            return numericString;
        },
        bulkOperationPrompt(status) {
            this.bulkOperationStatus = status;
            this.showRemovePrompt = true;
        },
        async sortChanged(context) {
            if (!context.sortBy) return;
            this.queryParams.sortBy = snakeCase(context.sortBy);
            this.queryParams.sortOrder = context.sortDesc ? 'desc' : 'asc';
            await this.refreshRecords(1);
        },
        async refreshRecords(page, params = {}) {
            this.queryParams.page = page;
            this.queryParams = {...this.queryParams, ...params};
            await this.fetchReserveList();
        },
        async fetchReserveList() {
            this.$router
                .replace({
                    query: this.queryObject,
                })
                .catch(() => {});
            await this.blockingRequest('reservations/fetchList', {...this.queryObject, status});
        },
        async onBulkOperationConfirmed() {
            this.showRemovePrompt = false;
            const orderHeaderIds = get(this.$refs, 'reserveTable.selectedRows', []).map(
                (item) => item.orderHeaderId
            );
            await this.blockingRequest('orders/updateMultiple', {
                orderHeaderIds,
                status: this.bulkOperationStatus,
            });
            if (this.bulkOperationStatus == this.bulkActions.ADD_TO_CART) {
                await this.blockingRequest('cart/fetchItemsCount');
            }
            await this.refreshRecords(this.selectPageAfterAction);
            this.bulkOperationStatus = null;
        },
    },
    created() {
        this.clearList();
    },
    async mounted() {
        this.queryParams = defaultQueryParams(
            this.isPreOrderListMode,
            this.isDistributorSurgeonMode,
            this.isDistributorMode
        );
        this.queryParams = merge({}, this.queryParams, this.$route.query);
        await this.fetchReserveList();
    },
};
</script>
<style></style>
